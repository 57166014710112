import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import 'dayjs/locale/zh-cn.js'
import 'dayjs/locale/en.js'

dayjs.extend(calendar)
dayjs.extend(advancedFormat)
dayjs.extend(localizedFormat)

if (/zh/.test(navigator.language)) {
  dayjs.locale('zh-cn')
} else {
  dayjs.locale('en')
}

export default dayjs
