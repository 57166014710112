import { createApp } from 'vue'
import ToastComponent from './components/Toast.vue'

export default {
  success(options) {
    if (typeof options === 'string') {
      options = {title: options}
    }
    this.create({
      variant: 'success',
      icon: 'check-circle',
      title: options.title,
      context: options.context
    })
  },

  error(options) {
    if (typeof options === 'string') {
      options = {title: options}
    }
    this.create({
      variant: 'error',
      icon: 'error',
      title: options.title,
      context: options.context
    })
  },

  create(props) {
    let toastContainer = document.body.querySelector('.toast-container')
    if (!toastContainer) {
      toastContainer = document.createElement('div')
      toastContainer.classList.add('toast-container')
      document.body.appendChild(toastContainer)
    }

    const toastEl = document.createElement('div')
    toastEl.className = 'toast-item'
    const toast = createApp(ToastComponent, {
      ...props,
      onClose() {
        toast.unmount()
        toastEl.remove()
      }
    })
    toast.mount(toastEl)
    toastContainer.appendChild(toastEl)
  }
}
