<template>
  <transition name="fade-down" @after-leave="onClose">
    <div
      class="toast"
      v-if="active"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
      :class="[variant]"
    >
      <div class="close" @click="close(true)">
        <img src="@exsat/app/src/assets/img/close.svg" alt="Close">
      </div>
      <div class="icon">
        <slot name="icon">
          <img :src="getSvg(icon || 'check')" alt="" />
        </slot>
      </div>
      <div class="message">
        <div class="message-title" v-html="title"></div>
        <div class="message-context" v-html="context.trim()" v-if="context"></div>
      </div>
      <div class="timeout-indicator" v-if="remainTime">
        <div class="timeout-indicator-bar" :style="{width: barWidth}"></div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { getSvg } from '@exsat/app/src/utils.js'
import { computed, onMounted, ref, watch } from 'vue'

let t = null
let interval = null

const active = ref(false)
const hovering = ref(false)
const remainTime = ref(0)

const props = defineProps({
  title: String,
  context: String,
  icon: String,
  variant: String,
  duration: {
    type: [Number, String],
    default: 5000
  },
  onClose: Function
})

const barWidth = computed(() => {
  return (remainTime.value / Number(props.duration) * 100) + '%'
})

watch(hovering, (val) => {
  if (!val && remainTime.value <= 0) {
    close()
  }
})

onMounted(() => {
  active.value = true
  initTimeout()
})

function close(force) {
  clearTimeout(t)
  clearInterval(interval)
  if (hovering.value && !force) {
    return
  }
  active.value = false
}

function initTimeout() {
  remainTime.value = Number(props.duration)
  initBar()
  t = setTimeout(() => {
    close()
  }, Number(props.duration))
}

function initBar() {
  clearInterval(interval)
  interval = setInterval(() => {
    remainTime.value = remainTime.value - 20
    if (remainTime.value < 0 || !active.value) {
      clearInterval(interval)
    }
  }, 20)
}
</script>

<style lang="scss" scoped>
.fade-down-enter-active,
.fade-down-leave-active {
  transition: 0.3s;
}

.fade-down-enter-from,
.fade-down-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.toast {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 400px;
  padding: 16px;
  background-color: var(--bg-secondary-color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  z-index: 30;
  color: var(--text-body);
  border-radius: var(--radius-xl);
  user-select: none;
  overflow: hidden;
  gap: var(--spacing-lg);
  box-shadow: var(--shadow-md);
  word-break: break-all;

  .message {
    flex: 1;
    min-width: 0;
  }

  &.success {
    .message-title {
      color: #47cd89;
    }
  }

  &.error {
    .message-title {
      color: #f97066;
    }
  }

  .message-title {
    font-size: var(--font-md);
    line-height: 1.5;
  }

  .message-context {
    font-size: var(--font-sm);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
    transition: 0.3s;
    max-height: 1.5em;
  }

  &:hover {
    user-select: auto;

    .close {
      opacity: 1;
    }

    .message-context {
      white-space: pre-wrap;
      max-height: 500px;
    }
  }

  .icon {
    user-select: none;

    img {
      width: 1.5em;
      height: 1.5em;
    }
  }
}

.close {
  position: absolute;
  top: 18px;
  right: 14px;
  cursor: pointer;
  opacity: 0;
  transition: .3s;

  img {
    width: 18px;
    height: 18px;
  }
}

.timeout-indicator {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
  background-color: #ccc;

  .timeout-indicator-bar {
    position: relative;
    height: 100%;

    .success & {
      background-color: #47cd89;
    }

    .error & {
      background-color: #f97066;
    }
  }
}
</style>
