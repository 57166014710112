import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'staking',
      component: () => import('./views/Staking.vue')
    },
    {
      path: '/my-staking',
      name: 'myStaking',
      component: () => import('./views/MyStaking.vue')
    },
    {
      path: '/operation',
      name: 'operation',
      component: () => import('./views/Operation.vue')
    },
    {
      path: '/synchronizers',
      name: 'synchronizers',
      component: () => import('./views/Synchronizers.vue')
    },
    {
      path: '/synchronizers/:id',
      name: 'synchronizer',
      component: () => import('./views/Synchronizer.vue')
    },
    {
      path: '/validators/:id',
      name: 'validator',
      component: () => import('./views/Validator.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('./views/NotFound.vue')
    }
  ]
})

export default router
