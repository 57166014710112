<template>
  <transition name="fade">
    <div class="dimmer" v-if="$modal.converter" @click="$modal.converter = false"></div>
  </transition>
  <transition name="fade-down">
    <div class="modal-container" id="btcConverter" v-if="$modal.converter">
      <div class="modal" @click.stop>
        <div class="modal-header">
          <div class="modal-title">Convert</div>
          <div class="close" @click="$modal.converter = false">&times;</div>
        </div>
        <div class="modal-body">
          <div class="convert-area">
            <div class="input-area source">
              <div class="header">
                <label for="source">Pay</label>
                <div class="wallet-value">
                  <icon value="wallet" />
                  {{ sourceTotal }}
                  {{ sourceToken === 'btc' ? 'BTC' : 'XBTC' }}
                </div>
              </div>
              <div class="input">
                <input type="number" id="source" v-model="sourceValue" placeholder="Input Amount" />
                <div class="token-sign">
                  <icon class="icon" :value="sourceToken === 'btc' ? 'BTC' : 'XBTC'" />
                  {{ sourceToken.toUpperCase() }}
                </div>
                <div class="border"></div>
              </div>
            </div>
            <div class="button-area">
              <button class="btn btn-secondary" @click="switchSource">
                <icon value="convert" />
              </button>
            </div>
            <div class="input-area target">
              <div class="header">
                <label>Receive</label>
                <div class="wallet-value">
                  <icon value="wallet" />
                  {{ targetTotal }}
                  {{ sourceToken === 'btc' ? 'XBTC' : 'BTC' }}
                </div>
              </div>
              <div class="input">
                <input type="number" placeholder="-" :value="sourceValue" disabled />
                <div class="token-sign">
                  <icon class="icon" :value="sourceToken === 'btc' ? 'XBTC' : 'BTC'" />
                  {{ sourceToken === 'btc' ? 'XBTC' : 'BTC' }}
                </div>
                <div class="border"></div>
              </div>
            </div>
          </div>
          <div class="btn-area mt-3">
            <button class="btn btn-secondary btn-block" disabled v-if="!sourceValue">
              Enter Amount
            </button>
            <button class="btn btn-outline-orange btn-block" v-else-if="converting">
              Converting
            </button>
            <button class="btn btn-secondary text-red btn-block" disabled v-else-if="insufficient">
              Insufficient Funds
            </button>
            <button class="btn btn-primary btn-block" @click="doConvert" v-else>Convert</button>
          </div>
          <div class="mt-1 text-gray text-sm text-center">No fee for conversion. But please reserve some BTC for gas.</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed, inject, onBeforeUnmount, ref, watch } from 'vue'
import { getBalance, getPublicClient, getWalletClient } from '@wagmi/core'
import { getContract } from 'viem'
import { displayValue, parseInputValue } from '@/utils.js'
import xbtcAbi from '../abi/xbtc.json'
import erc20Abi from '../abi/erc20.json'

const wagmiConfig = inject('wagmiConfig')
const $tokenValue = inject('$tokenValue')
const { tokenList } = inject('config')
const checkConnection = inject('checkConnection')
const account = inject('account')
const toast = inject('toast')

const $modal = inject('modal')

watch(
  () => $modal.converter,
  (val) => {
    document.documentElement.style.overflow = val ? 'hidden' : null
    if (val) {
      getTokenValues()
    }
  }
)

onBeforeUnmount(() => {
  document.documentElement.style.overflow = null
})

const sourceToken = ref('btc')
const sourceValue = ref('')

const btcTotal = ref(0)
const xbtcTotal = ref(0)

const sourceTotal = computed(() => {
  return sourceToken.value === 'btc' ? btcTotal.value : xbtcTotal.value
})
const targetTotal = computed(() => {
  return sourceToken.value === 'btc' ? xbtcTotal.value : btcTotal.value
})
const insufficient = computed(() => {
  console.log(sourceTotal.value, sourceValue.value)
  return sourceTotal.value < sourceValue.value
})

const converting = ref(false)

async function getTokenValues() {
  await checkConnection()

  if (!account.value) {
    return
  }

  getBalance(wagmiConfig, { address: account.value }).then((r) => {
    btcTotal.value = displayValue(r.value.toString(), r.decimals)
  })

  const publicClient = await getPublicClient(wagmiConfig)
  const contract = getContract({
    address: tokenList['XBTC'].address,
    abi: erc20Abi,
    client: publicClient
  })

  contract.read.balanceOf([account.value]).then((r) => {
    xbtcTotal.value = displayValue(r.toString(), tokenList['XBTC'].precision)
  })
}

async function withdraw(amount) {
  const walletClient = await getWalletClient(wagmiConfig)
  const publicClient = await getPublicClient(wagmiConfig)

  const tokenInfo = tokenList['XBTC']

  if (!tokenInfo || !tokenInfo.stake_address) {
    return
  }

  const hash = await walletClient.writeContract({
    address: tokenInfo.address,
    abi: xbtcAbi,
    functionName: 'withdraw',
    args: [amount]
  })

  await publicClient.waitForTransactionReceipt({
    confirmations: 1,
    hash
  })

  return hash
}

async function deposit(amount) {
  const walletClient = await getWalletClient(wagmiConfig)
  const publicClient = await getPublicClient(wagmiConfig)

  const tokenInfo = tokenList['XBTC']

  if (!tokenInfo || !tokenInfo.stake_address) {
    return
  }

  const hash = await walletClient.writeContract({
    address: tokenInfo.address,
    abi: xbtcAbi,
    functionName: 'deposit',
    args: [],
    value: amount
  })

  await publicClient.waitForTransactionReceipt({
    confirmations: 1,
    hash
  })

  return hash
}

async function doConvert() {
  try {
    converting.value = true
    await checkConnection()

    let amount = parseInputValue(sourceValue.value, 18)

    if (sourceToken.value === 'xbtc') {
      await withdraw(amount)
    } else {
      await deposit(amount)
    }

    sourceValue.value = ''
    setTimeout(() => {
      getTokenValues()
    }, 2000)

    toast.success({
      title: 'Convert success',
      context: displayValue(amount, 18) + ' BTC converted'
    })
  } catch (err) {
    toast.error({ title: 'Operation failed', context: err.message })
  } finally {
    converting.value = false
  }
}

function switchSource() {
  sourceToken.value = sourceToken.value === 'btc' ? 'xbtc' : 'btc'
}
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none;
}

.dimmer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  z-index: 900;
}

.modal {
  position: relative;
  width: 100%;
  max-width: 480px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-4xl);
  border: 1px solid var(--border-gray);
  background-color: #fff;
  pointer-events: all;
}

.modal-header {
  position: relative;
  padding: var(--spacing-xl);
  text-align: center;

  .modal-title {
    font-size: var(--heading-4);
    font-weight: 700;
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0.5em;
    font-size: 24px;
    cursor: pointer;
  }
}

.modal-body {
  padding: var(--spacing-xl);
}

.button-area {
  margin-top: -1rem;
  margin-bottom: -1rem;
  text-align: center;
  position: relative;
  z-index: 2;

  .btn {
    padding: 10px;
    line-height: 1;
    border-color: var(--border-gray);
    background-color: var(--bg-secondary-color);
  }
}

.input-area {
  .token-sign {
    padding: 2px 10px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    color: var(--text-body);
    overflow: hidden;
    background-color: var(--bg-secondary-color);

    .icon {
      width: 24px;
      height: 24px;
      border: #141414 4px solid;
      border-radius: 100px;
      background-color: #141414;
      margin-right: 0.5em;
    }
  }
}

.fade-down-enter-active,
.fade-down-leave-active {
  transition: 0.3s;
}

.fade-down-enter-from,
.fade-down-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
